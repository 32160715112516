import React from 'react'

const BaseFooter = () => {
  return (

    <footer id='sticky-footer' className='flex-shrink-0 py-4 bg-white text-dark-50'>
      {/* <div className='container d-flex justify-content-evenly'> */}
      <div className='container'>
        <div className='row'>
          <div className='col-12 d-flex d-sm-flex d-lg-flex justify-content-center justify-content-sm-center justify-content-lg-center justify-content-xxl-center mb-3'>
            <a href='https://www.iubenda.com/privacy-policy/67487759' className='iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe ' title='Privacy Policy '>Privacy Policy</a>
            &nbsp;
            <a href='https://www.iubenda.com/privacy-policy/67487759/cookie-policy' className='iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe ' title='Cookie Policy '>Cookie Policy</a>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-sm-6 text-center pb-3 pb-sm-0'>
            <span>Made with love by </span>

            <a href='https://www.devinterface.com' title='DevInterface Digital Agency Verona' target='_blank' rel='noopener noreferrer'>
              <img src='/images/logo-devinterface.svg' alt='Logo DevInterface' />
            </a>
          </div>
          <div className='col-12 col-sm-6 text-center pb-3 pb-sm-0'>
            <span>Powered by </span>
            <a href='https://www.startersaas.com' title='The Go / Node + React powered SaaS Template' target='_blank' rel='noopener noreferrer'>
              <img src='/images/logo-startersaas.svg' alt='Logo StarterSaas' />
            </a>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default BaseFooter

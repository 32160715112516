import React, { } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitSurvey } from 'api/mutations'
import { useMutation, useQueryClient } from 'react-query'
import ConfirmAlert from 'libs/confirmAlert'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Button } from 'react-bootstrap'
import Box from 'app/components/dashboard/Box'

const schema = yup.object().shape({
  name: yup.string().required(),
  surname: yup.string().required(),
  companyPhone: yup.string().required(),
  companyEmail: yup.string().email().required(),
  jobTitle: yup.string().required(),
  companyName: yup.string().required(),
  companyUrl: yup.string().required(),
  companySector: yup.string().ensure().required(),
  companySocialPresent: yup.string().ensure().required(),
  whyService: yup.array().required(),
  socialMediaExperience: yup.string().required(),
  serviceScope: yup.array().required(),
  haveBlog: yup.string().required(),
  findUs: yup.string().required()
})

const SurveyPage = (props) => {
  const { t } = useTranslation()

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })

  const queryClient = useQueryClient()
  const mutation = useMutation(SubmitSurvey, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Me'])
    }
  })

  const onSubmit = async data => {
    const response = await mutation.mutateAsync({ accountId: props.user.accountId, data: data })
    if (response) {
      ConfirmAlert.success(t('surveyPage.submitted'))
      setTimeout(function () {
        window.location.href = '/'
      }, 1000)
    }
  }

  return (
    <Row>
      <Col xs={12}>
        <Box
          header={
            <div>
              <h1>Informazioni personali e aziendali</h1>
              <p>Inserisci tutti i dati per farci capire meglio le tue esigenze</p>
            </div>
          }
          body={
            <div>
              <Form id='survey-form' name='survey-form' data-name='Survey Form' onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId='formName'>
                  <Form.Label for="name">Nome</Form.Label>
                  <Form.Control type='text' maxLength='256' name='name' data-name='name' placeholder='' id='name' {...register('name', { required: true })} />
                  <span className='text-muted'>
                    {errors.name?.message}
                  </span>
                </Form.Group>
                <Form.Group controlId='formSurname'>
                  <Form.Label for="surname">Cognome</Form.Label>
                  <Form.Control type='text' maxLength='256' name='surname' data-name='surname' placeholder='' id='surname' {...register('surname', { required: true })} />
                  <span className='text-muted'>
                    {errors.surname?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formTelephone'>
                  <Form.Label for="companyPhone">Telefono</Form.Label>
                  <Form.Control type='text' maxLength='256' name='companyPhone' data-name='companyPhone' placeholder='' id='companyPhone' {...register('companyPhone', { required: true })} />
                  <span className='text-muted'>
                    {errors.companyPhone?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formCompanyEmail'>
                  <Form.Label for="companyEmail">Email</Form.Label>
                  <Form.Control type='text' maxLength='256' name='companyEmail' data-name='companyEmail' placeholder='' id='companyEmail' {...register('companyEmail', { required: true })} />
                  <span className='text-muted'>
                    {errors.companyEmail?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formJobTitle'>
                  <Form.Label for="jobTitle">Titolo di lavoro</Form.Label>
                  <Form.Control type='text' maxLength='256' name='jobTitle' data-name='jobTitle' placeholder='' id='jobTitle' {...register('jobTitle', { required: true })} />
                  <span className='text-muted'>
                    {errors.jobTitle?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formCompanyName'>
                  <Form.Label for="companyName">Nome Azienda</Form.Label>
                  <Form.Control type='text' maxLength='256' name='companyName' data-name='companyName' placeholder='' id='companyName' {...register('companyName', { required: true })} />
                  <span className='text-muted'>
                    {errors.companyName?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formCompanyUrl'>
                  <Form.Label for="companyUrl">Sito Azienda</Form.Label>
                  <Form.Control type='text' maxLength='256' name='companyUrl' data-name='companyUrl' placeholder='' id='companyUrl' {...register('companyUrl', { required: true })} />
                  <span className='text-muted'>
                    {errors.companyUrl?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formCompanySector'>
                  <Form.Label for="companySector">Qual è il settore della tua attività?</Form.Label>
                  <Form.Check name='companySector' type='radio' placeholder='' id='Amministrazione' label='Amministrazione' value='Amministrazione' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Arte/Design/Media' label='Arte/Design/Media' value='Arte/Design/Media' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Business' label='Business' value='Business' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id="Assistenza all'infanzia" label="Assistenza all'infanzia" value="Assistenza all'infanzia" {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Educazione' label='Educazione' value='Educazione' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Ingegneria' label='Ingegneria' value='Ingegneria' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Finanza/Contabilità' label='Finanza/Contabilità' value='Finanza/Contabilità' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Assistenza sanitaria/Sanità' label='Assistenza sanitaria/Sanità' value='Assistenza sanitaria/Sanità' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Assicurazioni' label='Assicurazioni' value='Assicurazioni' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Legale' label='Legale' value='Legale' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Manifatturiero' label='Manifatturiero' value='Manifatturiero' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Infermieristica' label='Infermieristica' value='Infermieristica' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Risorse umane' label='Risorse umane' value='Risorse umane' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Marketing/PR' label='Marketing/PR' value='Marketing/PR' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Immobiliare' label='Immobiliare' value='Immobiliare' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Ristorazione' label='Ristorazione' value='Ristorazione' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Turismo' label='Turismo' value='Turismo' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Vendita al dettaglio' label='Vendita al dettaglio' value='Vendita al dettaglio' {...register('companySector', { required: true })} />
                  <Form.Check name='companySector' type='radio' placeholder='' id='Tecnologico' label='Tecnologico' value='Tecnologico' {...register('companySector', { required: true })} />
                  <span className='text-muted'>
                    {errors.companySector?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formCompanySocialPresent'>
                  <Form.Label for="companySocialPresent">Devi creare un profilo social da zero?</Form.Label>
                  <Form.Control size='lg' name='companySocialPresent' data-name='companySocialPresent' id='companySocialPresent' as='select' {...register('companySocialPresent', { required: true })}>
                    <option value=''>Scegli un'opzione</option>
                    <option value='Sì, la mia attività non è ancora presente su alcun social'>Sì, la mia attività non è ancora presente su alcun social</option>
                    <option value='No, ho già dei canali social'>No, ho già dei canali social</option>
                  </Form.Control>
                  <span className='text-muted'>
                    {errors.companySocialPresent?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formCompanySocialMedia'>
                  <Form.Label for="companySocialMedia">Canali Social Media attivi</Form.Label>
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='Facebook' label='Facebook' value='Facebook' {...register('companySocialMedia', { required: true })} />
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='Instagram' label='Instagram' value='Instagram' {...register('companySocialMedia', { required: true })} />
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='LinkedIn' label='LinkedIn' value='LinkedIn' {...register('companySocialMedia', { required: true })} />
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='Twitter' label='Twitter' value='Twitter' {...register('companySocialMedia', { required: true })} />
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='TikTok' label='TikTok' value='TikTok' {...register('companySocialMedia', { required: true })} />
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='YouTube' label='YouTube' value='YouTube' {...register('companySocialMedia', { required: true })} />
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='Pinterest' label='Pinterest' value='Pinterest' {...register('companySocialMedia', { required: true })} />
                  <Form.Check name='companySocialMedia' type='checkbox' placeholder='' id='Snapchat' label='Snapchat' value='Snapchat' {...register('companySocialMedia', { required: true })} />
                  <span className='text-muted'>
                    {errors.companySocialMedia?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formWhyService'>
                  <Form.Label for="whyService">Perché desideri provare questo servizio?</Form.Label>
                  <Form.Check name='whyService' type='checkbox' placeholder='' id='Desidero aumentare la mia visibilità' label='Desidero aumentare la mia visibilità' value='Desidero aumentare la mia visibilità' {...register('whyService', { required: true })} />
                  <Form.Check name='whyService' type='checkbox' placeholder='' id='Desidero aumentare la conoscenza della mia attività' label='Desidero aumentare la conoscenza della mia attività' value='Desidero aumentare la conoscenza della mia attività' {...register('whyService', { required: true })} />
                  <Form.Check name='whyService' type='checkbox' placeholder='' id='Desidero generare contatti' label='Desidero generare contatti' value='Desidero generare contatti' {...register('whyService', { required: true })} />
                  <Form.Check name='whyService' type='checkbox' placeholder='' id='Desidero migliorare il posizionamento del mio sito web sui motori di ricerca' label='Desidero migliorare il posizionamento del mio sito web sui motori di ricerca' value='Desidero migliorare il posizionamento del mio sito web sui motori di ricerca' {...register('whyService', { required: true })} />
                  <span className='text-muted'>
                    {errors.whyService?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formSocialMediaExperience'>
                  <Form.Label for="socialMediaExperience">Hai già utilizzato i canali social?</Form.Label>
                  <Form.Control size='lg' name='socialMediaExperience' data-name='socialMediaExperience' id='socialMediaExperience' as='select' {...register('socialMediaExperience', { required: true })}>
                    <option value=''>Scegli un'opzione</option>
                    <option value='Si'>Si</option>
                    <option value='No'>No</option>
                  </Form.Control>
                  <span className='text-muted'>
                    {errors.socialMediaExperience?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formServiceScope'>
                  <Form.Label for="serviceScope">Che cosa desideri ottenere con le pubblicazioni sui social?</Form.Label>
                  <Form.Check name='serviceScope' type='checkbox' placeholder='' id='Pubblicizzare prodotti' label='Pubblicizzare prodotti' value='Pubblicizzare prodotti' {...register('serviceScope', { required: true })} />
                  <Form.Check name='serviceScope' type='checkbox' placeholder='' id="Far conoscere l'azienda" label="Far conoscere l'azienda" value="Far conoscere l'azienda" {...register('serviceScope', { required: true })} />
                  <Form.Check name='serviceScope' type='checkbox' placeholder='' id='Ampliare il mio pubblico' label='Ampliare il mio pubblico' value='Ampliare il mio pubblico' {...register('serviceScope', { required: true })} />
                  <Form.Check name='serviceScope' type='checkbox' placeholder='' id='Rafforzare il rapporto con il mio pubblico' label='Rafforzare il rapporto con il mio pubblico' value='Rafforzare il rapporto con il mio pubblico' {...register('serviceScope', { required: true })} />
                  <Form.Check name='serviceScope' type='checkbox' placeholder='' id='Sviluppare il mio marchio' label='Sviluppare il mio marchio' value='Sviluppare il mio marchio' {...register('serviceScope', { required: true })} />
                  <Form.Check name='serviceScope' type='checkbox' placeholder='' id='Aumentare il traffico verso il mio sito web' label='Aumentare il traffico verso il mio sito web' value='Aumentare il traffico verso il mio sito web' {...register('serviceScope', { required: true })} />
                  <Form.Check name='serviceScope' type='checkbox' placeholder='' id="Tenere d'occhio i miei concorrenti"  label="Tenere d'occhio i miei concorrenti" value="Tenere d'occhio i miei concorrenti" {...register('serviceScope', { required: true })} />
                  <span className='text-muted'>
                    {errors.serviceScope?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formHaveBlog'>
                  <Form.Label for="haveBlog">Hai un blog sul tuo sito?</Form.Label>
                  <Form.Control size='lg' name='haveBlog' data-name='haveBlog' id='haveBlog' as='select' {...register('haveBlog', { required: true })}>
                    <option value=''>Scegli un'opzione</option>
                    <option value='Si'>Si</option>
                    <option value='No'>No</option>
                  </Form.Control>
                  <span className='text-muted'>
                    {errors.haveBlog?.message}
                  </span>
                </Form.Group>


                <Form.Group controlId='formFindUs'>
                  <Form.Label foir="findUs">Come ci hai conosciuti?</Form.Label>
                  <Form.Control size='lg' name='findUs' data-name='findUs' id='findUs' as='select' {...register('findUs', { required: true })}>
                    <option value=''>Scegli un'opzione</option>
                    <option value='Sito Internet'>Sito Internet</option>
                    <option value='Pubblicità (Google ads, Facebook ads, Instagram ads...)'>Pubblicità (Google ads, Facebook ads, Instagram ads...)</option>
                    <option value='Passaparola'>Passaparola</option>
                    <option value='Altro'>Altro</option>
                  </Form.Control>
                  <span className='text-muted'>
                    {errors.findUs?.message}
                  </span>
                </Form.Group>

                <Form.Group controlId='formComment'>
                  <Form.Label for="comments">Parlaci della tua azienda e/o dei tuoi prodotti</Form.Label>
                  <Form.Control as='textarea' rows="10" maxLength='256' name='comments' data-name='comments' placeholder='Raccontaci di cosa si occupa la tua azienda o quali sono i prodotti che vuoi promuovere' id='comments' {...register('comments', { required: true })} />
                  <span className='text-muted'>
                    {errors.comments?.message}
                  </span>
                </Form.Group>

                <Button type='submit' className='custom-btn green w-100-perc' data-wait='Attendere prego...'>Invia</Button>
              </Form>
            </div>
          }
        />
      </Col>
    </Row>
  )
}
export default SurveyPage

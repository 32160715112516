import React from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from 'routes/PrivateRoute'
import PrivateActiveRoute from 'routes/PrivateActiveRoute'
import OnlyPublicRoute from 'routes/OnlyPublicRoute'
import withCurrentUser from 'hoc/withCurrentUser'
import PublicLayout from 'app/components/layout/PublicLayout'
import AuthLayout from 'app/components/layout/AuthLayout'
import PrivateLayout from 'app/components/layout/PrivateLayout'
import LoginPage from 'app/pages/auth/LoginPage'
import ForgotPasswordPage from 'app/pages/auth/ForgotPasswordPage'
import ResetPasswordPage from 'app/pages/auth/ResetPasswordPage'
import ResendActivationPage from 'app/pages/auth/ResendActivationPage'
import RegisterPage from 'app/pages/auth/RegisterPage'
import ActivateAccountPage from 'app/pages/auth/ActivateAccountPage'
import DashboardPage from 'app/pages/dashboard/DashboardPage'
import PlanPage from 'app/pages/plan/PlanPage'
import SubscribePlanPage from 'app/pages/plan/SubscribePlanPage'
import AddCardPage from 'app/pages/user/AddCardPage'
import EditUserPage from 'app/pages/user/EditUserPage'
import EditAccountPage from 'app/pages/user/EditAccountPage'
import IndexPage from 'app/pages/public/IndexPage'
import SurveyPage from 'app/pages/user/SurveyPage'

const Private = withCurrentUser(PrivateRoute)
const PrivateActive = withCurrentUser(PrivateActiveRoute)
const OnlyPublic = withCurrentUser(OnlyPublicRoute)

const ApplicationRouter = () => {
  return (
    <Switch>
      <OnlyPublic exact path='/' component={LoginPage} layout={AuthLayout} />
      <OnlyPublic
        exact
        path='/auth/login'
        component={LoginPage}
        layout={AuthLayout}
      />
      <OnlyPublic
        exact
        path='/auth/forgot-password'
        component={ForgotPasswordPage}
        layout={AuthLayout}
      />
      <OnlyPublic
        exact
        path='/auth/resend-activation'
        component={ResendActivationPage}
        layout={AuthLayout}
      />
      <OnlyPublic
        exact
        path='/auth/reset-password/:email'
        component={ResetPasswordPage}
        layout={AuthLayout}
      />
      <OnlyPublic
        exact
        path='/auth/activate/:email'
        component={ActivateAccountPage}
        layout={AuthLayout}
      />
      <OnlyPublic
        exact
        path='/auth/register'
        component={RegisterPage}
        layout={AuthLayout}
      />
      <PrivateActive
        exact
        path='/dashboard'
        layout={PrivateLayout}
        allowedRoles={['admin', 'user']}
        component={DashboardPage}
      />
      <Private
        exact
        path='/plan/subscribe'
        layout={PrivateLayout}
        allowedRoles={['admin']}
        component={SubscribePlanPage}
      />
      <Private
        exact
        path='/plan'
        layout={PrivateLayout}
        allowedRoles={['admin']}
        component={PlanPage}
      />
      <Private
        exact
        path='/survey'
        layout={PrivateLayout}
        allowedRoles={['admin']}
        component={SurveyPage}
      />
      <PrivateActive
        exact
        path='/card/add'
        layout={PrivateLayout}
        allowedRoles={['admin']}
        component={AddCardPage}
      />
      <PrivateActive
        exact
        path='/user/edit'
        layout={PrivateLayout}
        allowedRoles={['admin', 'user']}
        component={EditUserPage}
      />
      <PrivateActive
        exact
        path='/account/edit'
        layout={PrivateLayout}
        allowedRoles={['admin']}
        component={EditAccountPage}
      />
    </Switch>
  )
}

export default ApplicationRouter

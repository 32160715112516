export default {
  loginPage: {
    access: 'Access',
    register: 'Register',
    forgotPassword: 'Forgot password?',
    didntReceivedActivationEmail: "Didn't receive the activation email?",
    confirm: 'Confirm'
  },
  registerPage: {
    invalidSubdomain: 'Invalid subdomain format',
    termsAccepted: 'The terms and conditions must be accepted.',
    confirmEmailSent:
      'An email with the activation token has been sent. Check your inbox.',
    emailPasswordInvalid: 'Email or password invalid',
    subdomain: 'Subdomain',
    registerConsent:
      "By clicking on the 'Register' button I consent the terms and conditions of the service and I understand that my account's informations will be used according to the privacy policy.",
    terms: 'Terms and Conditions',
    privacy: 'Privacy and Policy',
    marketingConsent:
      'Articoli e Social is committed to protecting and respecting your privacy, and will only use your personal information to administer your account and to provide the service you have requested. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you.',
    alreadyRegistered: 'Already registered?',
    didntReceivedActivationEmail: "Didn't receive the activation email?",
    confirm: 'Confirm',
    register: 'Register'
  },
  forgotPasswordPage: {
    passwordEmailSent:
      'An email with the reset password code has been sent. Check your inbox',
    resetPasswordFailure: 'Reset password went wrong, please retry',
    sendCode: 'Send reset code',
    back: 'Back to login page',
    passwordReset: 'Password reset'
  },
  resendActivationPage: {
    emailSent:
      'An email with the activation token has been sent. Check your inbox.',
    invalidEmail: 'Email not valid',
    resendActivationCode: 'Resend activation code',
    back: 'Back to login page',
    confirm: 'Confirm'
  },
  resetPasswordPage: {
    passwordUpdated: 'Your password has been successfully updated',
    resetPasswordFailure: 'Reset password went wrong, please retry',
    changePassword: 'Change password',
    updatePassword: 'Update password',
    back: 'Back to login page',
    token: 'Code'
  },
  activateAccountPage: {
    accountActivated: 'Your account has been activated',
    activationFailure: 'Activation went wrong, please retry',
    activateAccount: 'Activate Account',
    activate: 'Activate',
    back: 'Back to login page',
    token: 'Code'
  },
  accountForm: {
    companyName: 'Company name',
    phoneNumber: 'Phone number',
    billingAddress: 'Billing address',
    vatNumber: 'VAT number',
    update: 'Update billing details',
    companyCountry: 'Country',
    selectOne: 'Select one',
    pec: 'PEC',
    sdi: 'SDI',
    onlyForItalians: ' only for italian companies with VAT'
  },
  addCardPage: {
    creditCard: 'Credit card data'
  },
  editAccountPage: {
    detailsUpdated: 'Billing details have been successfully updated',
    details: 'Billing details'
  },
  editUserPage: {
    passwordRequired: 'Password is required',
    passwordUpdated: 'Your password has been successfully updated',
    changePassword: 'Change password',
    insertNewPassword: 'Insert the new password',
    updatePassword: 'Update password'
  },
  stripeCCForm: {
    cardAdded: 'Card added successfully',
    cardOwner: 'Credit card owner',
    addCard: 'Add this card',
    addCardFailed: 'Card is not added due to some errors:'
  },
  planCard: {
    updateSubscription: 'Update your subscription',
    areYouSure: 'Are you sure to update your plan?',
    yes: 'Yes',
    no: 'No',
    planUpdated: 'Plan successfully updated',
    month: '/month',
    year: '/year',
    changePlan: 'Change plan',
    currentPlan: 'Your curren plan',
    selectPlan: 'Select this plan',
    toPay: 'To pay'
  },
  planPage: {
    selectaPlan: 'Select a plan',
    monthly: 'Monthly plan',
    yearly: 'Yearly plan',
    deactivatedAccountNotice:
      "Your account isn't active. Please subscribe a plan to activate it."
  },
  stripeForm: {
    paymentFailed: 'Payment failed due to some errors:',
    paymentCompleted: 'Payment successfully completed',
    cardOwner: 'Credit card owner',
    subscribe: 'Subscribe'
  },
  subscribePlanPage: {
    billingDetails: 'Billing details',
    yourOrder: 'Your order',
    plan: 'Plan',
    invoicing: 'Invoicing',
    price: 'Price',
    monthly: 'Monthly plan',
    yearly: 'Yearly plan',
    creditCard: 'Credit card data',
    priceWithVat: 'Price with VAT',
    plusVat: '+ TAX',
    subscribe: 'Subscribe'
  },
  trialComponent: {
    youAreOnTrial:
      'You are currently working on the trial version of Articoli e Social.',
    trialEndsAt: 'Trial period ends at',
    goToPlans: 'Go to plans'
  },
  dashboardPage: {
    unsubscribe: 'Unsubscribe',
    areYouSureToUnsubscribe:
      'Are you sure to unsubscribe? In any case, your subscription will be active until the end of the paid period',
    yes: 'Yes',
    no: 'No',
    removeCard: 'Remove the card',
    areYouSureToRemoveCard: 'Are you sure to remove this card?',
    makeDefault: 'Make default',
    areYouSureMakeDefault: 'Are you sure to make this card the default one?',
    manageSubscription:
      'In this panel you can manage your subscription and check your payment history',
    failedPaymentAt: 'ATTENTION! You have a failed payment at',
    checkYourPayments:
      'Please check your due payment in the Payment History section or in your email inbox.',
    subscriptionDeactivateOn:
      'Your subscription will automatically deactivate on',
    subscriptionRenewOn: 'Your subscription will automatically renew on',
    yourSubscription: 'Your subscription',
    plan: 'Plan',
    price: 'Price',
    canceledAt: 'Canceled at',
    willDeactivateAt: 'Will deactivate at',
    willRenewOn: 'Will renew on',
    paymentFailedAt: 'Payment failed at',
    paymentMethods: 'Your payment methods',
    default: 'default',
    remove: 'remove',
    addCreditCard: 'Add a credit card',
    changePlan: 'Change plan',
    deleteSubscription: 'Delete subscription',
    paymentHistory: 'Payment history',
    paymentId: 'Payment ID',
    status: 'Status',
    date: 'Date',
    total: 'Total',
    paid: 'Paid',
    toPay: 'To pay',
    actions: 'Actions'
  },
  privateLayout: {
    billingDetails: 'Billing details',
    editUser: 'Edit user info',
    logout: 'Logout',
    toggle: 'Toggle'
  },
  surveyPage: {
    submitted: 'Your survey has been submitted'
  }
}

import React from 'react'
import BaseFooter from 'app/components/layout/BaseFooter'
import { Container, Image, Col, Row, Card } from 'react-bootstrap'
import '../../../css/authLayout/auth.css'

const AuthLayout = ({ children }) => {
  return (
		<div className='d-flex flex-column'>
			<div id='page-content' className='auth-content'>
				<Container>
					<Card className='card-auth m-t-20 m-b-20 shadow-box'>
						<Row>
							<Col lg={6} md={12} sm={12} className='img-none'>
								<div className='card-body text-center'>
									<Image rounded='true' src='/images/illustrazione-articoliesocial.svg' alt='Articoli e Social Subscription' className='img-responsive' />
								</div>
							</Col>
							<Col lg={6} md={10} sm={12}>
								<div className='card-body text-center'>
									<Image className='logo m-b-40' src='/images/logo-articoliesocial.svg' alt='Articoli e Social Logo' />
									{children}
								</div>
							</Col>
						</Row>
					</Card>
				</Container>
			</div>
      <BaseFooter />
		</div>
  )
}

export default AuthLayout
